@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap);
:root {
  --header-height: 3rem;
  --font-semi: 600;
}
:root {
  --first-color: #b56422;
  --second-color: #333333;
}
:root {
  --body-font: "Open Sans Condensed", sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
}
@media screen and (min-width: 768px) {
  :root {
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1rem;
  }
}
:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
}
:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overscroll-behavior: none;
}
body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--second-color);
  overflow-x: hidden;
}
h1,
h2,
p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
.corgi_color {
  color: var(--first-color);
}
.fade-in {
  animation: fadeIn 2.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fade-in-left {
  animation: fadeInLeft 2s;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    margin-left: 500px;
  }

  to {
    opacity: 1;
    margin-left: none;
  }
}
.fade-in-right {
  animation: fadeInRight 2s;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    margin-right: 500px;
  }

  to {
    opacity: 1;
    margin-right: none;
  }
}
.section-title {
  position: relative;
  font-size: var(--h2-font-size);
  color: var(--first-color);
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-4);
  text-align: center;
}
.section-title::after {
  position: absolute;
  content: "";
  width: 64px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  background-color: var(--first-color);
}
.section {
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.bd-grid {
  max-width: 1300px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(146, 161, 176, 0.15);
}
.nav-bar {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--font-semi);
}
.open-slide{
  display: flex;
  gap: 20px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: var(--header-height);
    right: -100%;
    width: 80%;
    height: 100%;
    padding: 2rem;
    background-color: var(--second-color);
    transition: 0.5s;
  }
}
.nav__item {
  margin-bottom: var(--mb-4);
}
.nav__link {
  position: relative;
  color: #fff;
}
.nav__link:hover {
  position: relative;
}
.nav__link:hover::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0.18rem;
  left: 0;
  top: 2rem;
  background-color: var(--first-color);
}
.nav__logo {
  color: var(--first-color);
}
#logo {
  color: var(--first-color);
}
.side-menu-icon {
  color: var(--second-color);
  padding-right: 8px;
  font-size: 25px;
}
.side-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--second-color);
  opacity: 0.9;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
  text-align: left;
}
.side-nav a {
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  font-size: 22px;
  color: #fff;
  display: block;
  transition: 0.3s;
}
.side-nav a:hover {
  color: #fff;
}
.side-nav .btn-close {
  position: absolute;
  top: 0;
  right: 22px;
  font-size: 36px;
  margin-left: 50px;
}
.btn-close {
  background-color: #333333;
  border: 0;
  color: #fff;
  opacity: 0.9;
}
.btn-close:hover {
  zoom: 105%;
  background-color: #fff;
  color: #333333;
}
#youtube {
  color: red;
}
#instagram {
  color: #fb3958;
}
#facebook {
  color: #4267b2;
}
#amazon {
  color: #ff9900;
}
#twitter {
  color: #1da1f2;
}
#tiktok {
  color: #000;
}
#github {
  color: #fff;
}
.secondary-color {
  color: var(--second-color) !important;
}
.logo-row {
  display: grid;
  grid-gap: 40px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-left: 2%;
  margin-right: 2%;
}
#AKC-canine-health-foundation-logo {
  pointer-events: none;
  max-height: 338px;
  width: 400px;
}
#AVMF-logo{
  pointer-events: none;
  background-color: #23395d;
  padding: 65px;
}
#PPSD-logo {
  pointer-events: none;
  max-height: 338px;
  width: 400px;
}
.purina-pro-plan-container {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: black;
  display: grid;
  row-gap: 40px;
}
.purina-pro-plan-header {
  font-size: xx-large;
  font-weight: 700;
  color: var(--first-color);
  text-align: center;
}
.purina-pro-plan-details {
  margin-top: 15px;
  color: white;
  display: grid;
  justify-items: center;
  gap: 10px;
  font-size: large;
}
.purina-pro-plan-img {
  width: 200px;
}
.virbac-img{
  width: 200px;
  height: 500px;
}
.amazon-logo-link {
  font-size: xx-large;
}
.dewormer-container{
  display: flex;
  column-gap: 80px;
  margin-top: 20px;
}
.panacur-container{
  display: grid;
  justify-items: center;
  row-gap: 10px;
}
.buyer-awareness-container {
  background-color: var(--second-color);
  color: white;
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.buyer-awareness-title {
  font-size: xx-large;
  font-weight: 700;
  color: var(--first-color);
  text-align: center;
}
.buyer-awareness-headers {
  text-align: center;
  font-size: x-large;
}
.buyer-awareness-paragraph-style {
  display: grid;
  justify-items: center;
  gap: 10px;
  font-size: large;
}
.admin-container {
  padding-top: 100px;
}
.file-icon {
  width: max-content;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: gray;
}
.file-icon:hover {
  color: var(--first-color);
  text-decoration: underline;
}
.home {
  height: calc(100vh - 3rem);
  row-gap: 1rem;
  margin-bottom: 25px;
  margin-top: 28px;
}
.home__data {
  align-self: center;
}
.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-5);
}
.home__title-color {
  color: var(--first-color);
}
.home__social {
  display: flex;
  flex-direction: column;
}
.home__social-icon {
  width: max-content;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: var(--second-color);
}
.home__social-icon:hover {
  color: var(--first-color);
}
.home__img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 250px;
}
#home_image {
  border-radius: 0.5rem;
}
.button {
  background-color: var(--first-color);
  color: #fff;
  padding: 0.75rem 2.5rem;
  font-weight: var(--font-semi);
  border-radius: 0.5rem;
  border: 2px solid var(--first-color);
}
.button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  zoom: 110%;
  color: var(--first-color);
  background-color: white;
  border: 2px solid var(--first-color);
}
.swal-button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 0.75rem 2.5rem;
  font-weight: var(--font-semi);
  border-radius: 0.5rem;
}
.swal-button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.embedded-youtube-trailer {
  height: 325px;
  border-radius: 0.5rem;
  margin-bottom: 5px;
}
.corgi-puppies-description {
  text-align: center;
  margin-bottom: var(--mb-4);
}
.unavailable-blurb {
  text-align: center;
}
.social__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.social-icon {
  width: max-content;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: var(--second-color);
  margin-left: 20px;
  margin-right: 20px;
}
#more_info {
  margin-bottom: 20px;
}
.available-puppies-blurb {
  text-align: center;
  margin-top: 25px;
  border: 2px solid var(--second-color);
  padding: 2%;
  border-radius: 1%;
}
.registration-header {
  background-color: #b56422;
  color: white;
  padding: 1.5%;
  border-radius: 1%;
}
.vaccination-status-header {
  color: #b56422;
  padding: 1.5%;
  border-radius: 1%;
}
.price-header {
  background-color: #b56422;
  color: white;
  padding: 1.5%;
  border-radius: 1%;
}
.price-estimate-header {
  color: #b56422;
  padding: 1.5%;
  border-radius: 1%;
}
.price-estimate-header p{
  margin-top: 10px;
}
.available-puppies-announcement{
  text-align: center;
  color: white;
  background-color: var(--first-color);
  line-height: 2;
  padding: 5%;
  margin-top: 20px;
}
.available-puppies-question{
  color: black;
  font-weight: bold;
  margin-top: 20px;
}
.available_puppies__container {
  row-gap: 2rem;
  color: white;
  background-color: var(--second-color);
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
  border-radius: 2%;
  padding: 5%;
  margin-bottom: 20px;
}
.available_puppies__card {
  /* box-shadow: 0 4px 25px rgba(14, 36, 49, 0.15); */
  box-shadow: 0 4px 25px rgba(250, 250, 250, 0.45);
  border-radius: 0.5rem;
  text-align: center;
  overflow: hidden;
  display: grid;
  justify-content: center;
}
.available_puppies__card img {
  transition: 1s;
  cursor: pointer;
  margin-top: 30px;
}
.available_puppies__card img:hover {
  transform: scale(1.1);
}
.available_puppies_description{
  padding-bottom: 25px;
}
.available-puppies-subtitle {
  margin-bottom: var(--mb-2);
}
.available-puppies-text {
  margin-bottom: var(--mb-2);
}
.available-puppies-info {
  margin-top: 10px;
  font-weight: 700;
}
.payment-methods-blurb {
  display: grid;
  row-gap: 10px;
  text-align: center;
  border: 2px solid rgb(250, 250, 250);
  padding: 20px;
  border-radius: 1%;
  background-color: #333333;
  color: white;
}
.payment-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin-top: 10px;
}
.not-accepted-payment-methods {
  border: 2px solid #333333;
  padding: 2%;
  border-radius: 1%;
  font-size: larger;
}
.not-accepted-payment-methods ul {
  padding-left: 30px;
}
.not-accepted-payment-methods ul li {
  list-style-type: disc;
}
.not-accepted-title {
  color: #8c1515;
  font-size: x-large;
  text-align: center;
}
.accepted-payment-methods {
  border: 2px solid #333333;
  padding: 2%;
  border-radius: 1%;
  font-size: larger;
}
.accepted-payment-methods ul {
  padding-left: 30px;
}
.accepted-payment-methods ul li {
  list-style-type: disc;
}
.accepted-title {
  color: #006400;
  font-size: x-large;
  text-align: center;
}
.no-fee-style {
  color: #006400;
}
.additional-fees-style {
  color: #8c1515;
}
.pro-tip-header {
  font-style: italic;
}
.financing-options-container {
  padding: 20px;
  display: grid;
  gap: 7px;
  border-radius: 1%;
  font-size: larger;
  background-color: #FDC500;
  color: white;
  text-align: center;
  margin-top: 30px;
}
.paypal-monthly-option-container{
  display: grid;
  justify-items: left;
  justify-content: center;
  gap: 5px;
  font-size: larger;
}
.paypal-hyperlink {
  padding-left: 8px;
  position: relative;
  color: #3b7bbf;
  font-weight: 700;
  text-decoration: none;
}
.paypal-hyperlink:hover {
  color: white;
  text-decoration: underline;
}
.return-policy-container {
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 20px;
  display: grid;
  gap: 7px;
  border-radius: 1%;
  font-size: larger;
  background-color: var(--second-color);
  color: white;
  margin-top: 30px;
}
.return-policy-container ul li {
  list-style-type: disc;
  margin-left: 10%;
}
.return-header{
  text-align: center;
}

#available-puppies-gender {
  font-size: x-large;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 25px;
}

.third-party-puppies-ads-blurb{
  text-align: center;
  display: grid;
  row-gap: 7px;
}
.third-party-puppies-ads-blurb p{
  font-size: large;
}
#third-party-puppies-ads-header{
  color: rgb(0,128,0);
  margin-bottom: 15px;
}
#third-party-puppies-ads-disclaimer{
  font-size: larger;
  font-weight: bold;
}
.third-party-puppies-ads__container {
  row-gap: 2rem;
  color: white;
  background-color: rgb(0,128,0);
  box-shadow: 0 10px 36px rgba(0,0,0, 0.15);
  border-radius: 2%;
  padding: 5%;
  margin-bottom: 50px;
}
.third-party-puppies-ads__card {
  box-shadow: 0 4px 25px rgba(250, 250, 250, 0.45);
  border-radius: 0.8rem;
  text-align: center;
  overflow: hidden;
}
.third-party-puppies-ads__card img {
  transition: 1s;
  cursor: pointer;
}
.third-party-puppies-ads__card img:hover {
  transform: scale(1.1);
}
.third-party-puppies-ads_description{
  padding-bottom: 25px;
}
.third-party-puppies-ads-subtitle {
  margin-bottom: var(--mb-2);
}
.third-party-puppies-ads-text {
  margin-bottom: var(--mb-2);
}
.third-party-puppies-ads-info {
  margin-top: 10px;
  font-weight: 700;
}
.stud-service-blurb{
  text-align: center;
  margin-bottom: 30px;
  border: 2px solid var(--second-color);
  padding: 2%;
  border-radius: 1%;
}
.stud-service-about{
  text-align: center;
  margin-bottom: 30px;
  padding: 2%;
}
.stud-service-info{
  margin-top: 10px;
  font-weight: 700;
}
.stud-service__img {
  display: grid;
  grid-gap: 25px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 50px;
}
.stud-service__img img {
  border-radius: 0.5rem;
  transition: 1s;
  cursor: pointer;
}
.stud-service__img img:hover {
  transform: scale(1.1);
}
.resource-documents-blurb{
  text-align: left;
  margin-bottom: 30px;
  border: 2px solid var(--second-color);
  padding: 2%;
  border-radius: 1%;
}
.resource-documents-info{
  margin-bottom: 15px;
}
.sales-agreement-form{
  border: 0; 
  overflow: hidden; 
  min-height: 800px; 
  min-width: 200px;
  margin-top: 35px;
}
.about__container {
  row-gap: 2rem;
  text-align: center;
}
.about__subtitle {
  margin-bottom: var(--mb-2);
}
.about__img {
  justify-self: center;
}
.about__img img {
  width: 200px;
  border-radius: 0.5rem;
}
.about__text {
  margin-top: 20px;
}
.about-us__container {
  text-align: center;
  margin-top: 25px;
}
.about-us__img {
  display: grid;
  grid-gap: 25px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-left: 2%;
  margin-right: 2%;
}
.about-us-blurb {
  margin-left: 12%;
  margin-right: 12%;
  margin-bottom: 2%;
}
.about-us__subtitle {
  margin-bottom: var(--mb-2);
}
#owner-title{
  text-align: center;
}
.about-us-text {
  margin-bottom: var(--mb-2);
}
#about-us-note {
  color: #b56422;
  font-weight: 700;
}
.about-us-values-container{
  gap: 20px;
  margin-bottom: 40px;
}
.about-charities {
  margin: 30px;
  text-align: center;
}
.about-charities p {
  margin-bottom: 10px;
}
.past-puppies__container {
  text-align: center;
}
.past-puppies-blurb {
  margin-bottom: 2%;
  border: 2px solid var(--second-color);
  padding: 2%;
  border-radius: 1%;
}
.past-puppies__subtitle {
  margin-bottom: var(--mb-2);
}
.past-puppies-text {
  margin-bottom: var(--mb-2);
}
.past-puppies__img {
  display: grid;
  grid-gap: 25px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 50px;
}
.past-puppies__img img {
  border-radius: 0.5rem;
  transition: 1s;
  cursor: pointer;
}
.past-puppies__img img:hover {
  transform: scale(1.1);
}
.our-customers-blurb {
  text-align: center;
  border: 2px solid var(--second-color);
  padding: 2%;
  border-radius: 1%;
  margin-bottom: 2%;
}
.our-customers__subtitle {
  margin-bottom: var(--mb-2);
}
.our-customers-text {
  margin-bottom: var(--mb-2);
}
.reviews-container {
  background-color: var(--first-color);
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.reviews-title {
  font-size: xx-large;
  font-weight: 700;
  color: white;
  text-align: center;
}
.yelp-reviews-container {
  display: grid;
  /* align-items: center; */
  gap: 25px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
}
.our-customers__img {
  display: grid;
  grid-gap: 25px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-left: 2%;
  margin-right: 2%;
}
.our-customers__img img {
  border-radius: 0.5rem;
  transition: 1s;
  cursor: pointer;
}
.our-customers__img img:hover {
  transform: scale(1.1);
}
.primary-contact__container {
  text-align: center;
  margin-bottom: var(--mb-4);
}
.contact-blurb {
  display: grid;
  row-gap: 15px;
  border: 2px solid var(--second-color);
  padding: 2%;
  border-radius: 1%;
}
.adoption-process-container {
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 30px;
  font-size: larger;
  text-align: left;
}
.adoption-process-container ul li {
  list-style-type: disc;
  margin-left: 10%;
}
.adoption-process-header{
  text-align: center;
  margin-top: 25px;
}
.social-media-instructions {
  margin-top: 50px;
  display: grid;
  row-gap: 15px;
  color: #8c1515;
}
.social-distancing-update {
  display: grid;
  row-gap: 15px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  color: var(--first-color);
  background-color: #f2f2f2;
  padding: 2%;
}
.contact-form-container {
  margin-top: 45px;
  margin-bottom: 50px;
}
.contact-title {
  font-size: xx-large;
  font-weight: 700;
  color: var(--first-color);
  text-align: center;
}
.contact-instructions {
  margin-top: 15px;
  margin-bottom: 35px;
}
.contact-form {
  display: grid;
  grid-template-areas: "none contact_form none";
  justify-self: center;
}
.privacy-policy-label{
  font-size: x-large;
  text-align: center;
}
.contact-form-privacy-policy-container{
  text-align: center;
  margin-top: 3px;
  margin-bottom: 35px;
  font-size: large;
}
.contact-form-privacy-policy:hover{
  text-decoration: underline;
}
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="tel"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
.message {
  height: 200px;
}
.screening-questions {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
}
.hcaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.contact__img {
  width: 300px;
  justify-self: center;
}
.contact__img img {
  border-radius: 0.5rem;
}
.contact-text {
  font-size: large;
}
.contact-button-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.contact-end-comment {
  margin-top: 10px;
  font-size: large;
  font-weight: 700;
}
.blog__header {
  background-image: url("./images/blog_stock_images/blog_banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px;
}
.blog__header_article1 {
  background-image: url("./images/blog_stock_images/blog1_stock_photo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 175px;
}
.blog__header_article2 {
  background-image: url("./images/blog_stock_images/blog2_stock_photo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 175px;
}
.blog-title {
  text-align: center;
  font-size: 50px;
  color: #fff;
}
#blog-header-text {
  font-size: 25px;
}
#blog-header-info {
  font-size: 20px;
}
#blog-blurb {
  font-size: 18px;
  margin-top: 45px;
  margin-left: 65px;
  margin-right: 65px;
  margin-bottom: 30px;
}
.blog-article-container {
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  text-align: initial;
  margin-bottom: 30px;
}
.blog-patriot-paws-image {
  margin-top: 25px;
}
.blog_list {
  margin-left: 5%;
  margin-right: 5%;
}
.blog-selections-title {
  position: relative;
  font-size: 37px;
  color: var(--first-color);
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-4);
  text-align: left;
}
.blog__container {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  column-gap: 2rem;
}
#blog-article-title {
  font-size: 25px;
  color: var(--first-color);
  font-weight: bold;
}
.blog-topic {
  margin-bottom: 40px;
}
.blog-topic-image {
  border-radius: 0.5rem;
}
.blog-article-description {
  margin-top: 10px;
}
.blog-embedded-images {
  width: 650px;
  height: auto;
  border-radius: 0.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.answer {
  font-size: 18px;
  margin-top: 10px;
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 20px;
}
.hyperlink {
  position: relative;
  color: var(--first-color);
  font-weight: 700;
  text-decoration: none;
}
.hyperlink:hover {
  text-decoration: underline;
}
.blog-social-media li {
  margin-top: 15px;
}
.footer {
  background-color: var(--second-color);
  color: #fff;
  text-align: center;
  font-weight: var(--font-semi);
}

.footer-header-container {
  padding-top: 20px;
}

.footer__title {
  font-size: 2rem;
  margin-bottom: var(--mb-2);
  color: var(--first-color);
}
.footer__title:hover {
  color: white;
  text-decoration: underline;
  zoom: 105%;
}

.footer__list {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
}
.footer__item {
  margin-bottom: 0;
  font-size: x-large;
}
.footer__link {
  color: white;
}
.footer__link:hover {
  text-decoration: underline;
  color: var(--first-color);
}
.footer-blurb {
  margin-top: 25px;
  padding-bottom: 20px;
  color: #fff;
}
#phone-footer-contact {
  font-size: x-large;
}
.privacy-policy-link{
  color: white;
}
.privacy-policy-link:hover{
  text-decoration: underline;
  color: grey;
}
.privacy-policy-container{
  display: grid;
  text-align: left;
  gap: 20px;
  padding: 8%;
  font-size: large;
  margin-left: 5%;
  margin-right: 5%;
}
.privacy-policy-container ul li {
  list-style-type: disc;
  margin-left: 60px;
  margin-bottom: 3px;
}
.privacy-policy-mod-dates{
  gap: 7px;
}
@media screen and (max-height: 600px) {
  #home-top {
    width: 0;
    height: 0;
  }
}
@media screen and (min-width: 768px) {
  /* @media screen and (min-width: 970px) { */
  body {
    margin: 0;
  }
  .section {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
  .section-title {
    margin-bottom: var(--mb-6);
  }
  .section-title::after {
    width: 80px;
    top: 3rem;
  }
  .nav-bar {
    height: calc(var(--header-height) + 1rem);
  }
  .nav__list {
    display: flex;
    padding-top: 0;
  }
  .nav__item {
    margin-left: var(--mb-6);
    margin-bottom: 0;
  }
  .nav__toggle {
    display: none;
  }
  .nav__link {
    color: var(--second-color);
  }
  .home {
    height: 100vh;
  }
  .home__data {
    align-self: flex-end;
  }
  .home__social {
    padding-top: 0;
    padding-bottom: 2.5rem;
    flex-direction: row;
    align-self: flex-end;
  }
  .home__social-icon {
    margin-bottom: 0;
    margin-right: var(--mb-4);
  }
  .home__img {
    width: 350px;
    bottom: 15%;
  }
  .file-icon {
    margin-bottom: 0;
    margin-right: var(--mb-4);
  }
  .available_puppies__container {
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    column-gap: 2rem;
  }
  .third-party-puppies-ads__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
  }
  .about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: initial;
  }
  .about__img img {
    width: 300px;
  }
  .contact__container {
    justify-items: center;
  }
  .contact__img {
    justify-self: center;
  }
  .blog__header {
    padding: 275px;
  }
}
@media screen and (max-width: 768px) {
  .footer__menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: start;
    justify-items: center;
  }
  .footer__list {
    display: block;
  }
  .footer__item {
    margin-top: 10px;
  }
}
@media screen and (min-width: 1060px) {
  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
  .home__img {
    right: 10%;
  }
}
@media screen and (max-width: 500px) {
  .blog__header {
    padding: 55px;
  }
  .blog__header_article1 {
    padding: 55px;
  }
  .blog__header_article2 {
    padding: 55px;
  }
  .blog-title {
    font-size: 35px;
  }
}

.not-found-page-container{
  margin-top: 40px;
  text-align: center;
  display: grid;
  justify-items: center;
  padding: 8%;
}
.not-found-page-img{
  margin-top: 40px;
  margin-bottom: 20px;
}
.go-home{
  font-size: xx-large;
  font-weight: bold;
  color: var(--first-color);
}
.go-home:hover{
  text-decoration: underline;
}
